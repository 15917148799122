<script setup lang="ts">
import type { ReminderType } from '@critical-dates'

const props = defineProps<{
  id: string
  name: string
  type: ReminderType
  date: string
  divisionId: string
}>()

const dayjs = useDayjs()

/* TODO: Redirect to Summary */
</script>

<template>
  <NuxtLink
    class="hover:bg-gray-750 hover:after:bg-primary group relative rounded-lg p-4 transition-colors duration-150 after:absolute after:left-0.5 after:top-[10%] after:h-4/5 after:w-0.5 after:rounded-full after:bg-white after:transition-all after:duration-150 after:content-[''] hover:cursor-pointer"
    :to="`/division/${divisionId}/lease/${id}/manager`"
  >
    <div class="ml-3 text-lg font-bold uppercase text-white">
      {{ dayjs(date).format('DD MMM') }}
    </div>
    <div
      class="bg-primary/5 group-hover:bg-primary mt-1 rounded-lg p-3 text-sm transition-colors duration-150"
    >
      <span
        class="mr-2 font-bold uppercase text-white transition-colors duration-150 group-hover:text-gray-800"
      >
        {{ type }}
      </span>
      <span
        class="text-gray-100 transition-all duration-150 group-hover:text-gray-800 group-hover:underline"
      >
        {{ name }}
      </span>
    </div>
  </NuxtLink>
</template>
